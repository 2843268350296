import * as styles from "./pricing.module.scss";

import Banner from "../components/Banner";
import Layout from "../components/Layout";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";

export default function Pricing() {
  return (
    <Layout>
      <SeoHelmet title="How we bill" />
      <Banner title="How We Bill" image="pricing" />
      <div className={styles.pageContent}>
        <p>
          We have a particular point-of-view on software development that frames how we
          work and bill.
        </p>
        <p>
          Average-quality, poorly-tested software applications become harder to change
          and maintain over time, as complexity builds and the risk to changing the code
          increases. We’ve felt this pain in the past, and we’ve learned to take a
          rigorous approach to software quality.
        </p>
        <p>
          We practice test-driven-development, build extensive automated tests into all
          our software, and have adopted a no-known-bugs practice. Baking quality into
          our processes leads to low-risk, easily-changed software that pays for itself
          in ease of maintenance and upgradeability. We find that it actually becomes
          faster over time to build new features and make changes to well-tested code.
        </p>
        <p>
          Working this way requires a skillset we've honed over many years. "Working
          faster" by doing lower-quality work requires a different skillset, one which
          we don't cultivate. This committment to a incremental, high-quality
          development process has a few implications.
        </p>
        <h2>We prefer long-term clients</h2>
        <p>
          We generally only work with clients that have the potential for a long-term
          relationship. This allows us to establish rapport and trust that improves the
          development process. We also prefer long-term projects, since we develop
          faster over time. One-off commodity applications (content-driven websites,
          Shopify apps, and the like) can be done cheaper by someone else.
        </p>
        <h2>We bill for time</h2>
        <p>
          We develop quite fast and can predict many things accurately, but we still
          prefer to bill based on time, rather than a fixed bid. It is a good fit for
          our business and long-term clients, because it makes sure you are not spending
          money on features you don't need, and allows our relationship to grow and
          develop over time in the ways that benefit your business. Sometimes you need
          more resources. Sometimes you don't need any active development. Often, you
          find out halfway through development you don't need the feature you thought
          you did. Software development is a fluid process, even for us, so we find that
          time-and-materials billing is in everyone's interest.
        </p>
        <p>
          That said, we understand clients will want to limit their liability early on.
          We have ways of designing our projects to make sure you don't end up laying
          down thousands of dollars with an unsatisfactory result. As mentioned above,
          we really only look for long-term clients, so if you're not satisfied, it
          doesn't do us any good.
        </p>
        <h2>We only bill programmer hours</h2>
        <p>
          You're paying for the time one of our programmers spends on your project. Our
          development process (and the investment we make in those programmers) obviates
          the need for dedicated project management or QA testing. Work is broken down
          into small tasks and continuously integrated and deployed, and all regression
          testing is done through automated tests at the unit, system, and UI level. The
          percentage of time our programmers are working on value-adding work is very
          high.
        </p>
        <p>
          What is included that you are not directly paying for is the guidance and
          support (pair programming, code review, architectural planning, etc.) done by
          Lithic principals with the programmers on your project. Our high quality
          engineering is our differentiation, and maintaining this doesn't happen by
          itself. It happens with consistent, dedicated investment, and this time is not
          billed to you.
        </p>
        <h2>So how much does it cost?</h2>
        <p>
          For most projects, we bill $1300 per programmer per day ($2000 per day for
          principals). We only bill for the days worked, so you are not billed for
          holidays and sick days.
        </p>
        <p>
          For circumstances requiring hourly billing, we bill $170/hr ($270/hr for
          principals). This can include additional resources on an existing project,
          projects requiring highly variable effort, or maintenance on existing
          projects.
        </p>
        <p>
          Note that other types of projects or development activities may be billed at a
          higher or lower rate, which we'll discuss upfront. Also, enterprise clients
          are billed a higher rate, due to the additional non-billable work required.
        </p>
        <h2>When do I get billed?</h2>
        <p>
          We prefer billing every 2 weeks on NET15, with payments made online via ACH
          through our payment portal. However, we understand you may have your own
          billing methods and cycles, and can work with those.
        </p>

        <h2>Can you make an exception</h2>
        <p>
          Probably. We are still a relatively small shop, with a set of diverse
          technical expertise, so we can be flexible and make exceptions as long as we
          think it's to all parties' benefit (in this type of relationship, anything to
          one party's benefit is really detrimental to all). One thing we normally can't
          go down on is our rate, since time is an inherently scarce resource. That
          said, we do offer flexible payment options for qualifying clients, but this is
          done on a case-by-case basis. But feel free to ask!
        </p>
        <h2>Can't I get this done cheaper?</h2>
        <p>
          Maybe! We know we're not cheap, but cost is relative, software is expensive,
          and experienced, competent contractors are rare. We can confidently say that
          you will not be able to get equally high-quality work done for less. You can
          probably get lower-quality work for cheaper, or comparable-quality work for
          more money (and, of course, definitely lower-quality work for more money).
        </p>
        <p>
          In some cases, you may have a problem that can be solved with some software
          customization (e.g. CMS theming) rather than custom software. We can recommend
          other consultancies or agencies that would be able to do great work at a lower
          cost than we can. And for certain types of problems, you may be able to solve
          them yourself using "No Code" solutions, like Webflow or Bubble.
        </p>
        <p>
          If you need custom software, then "can I get this done cheaper" really means
          "can I get by with lower-quality work?" Maybe! Our experience says that you
          rarely can, though. The problem with low-quality work is that the best-case
          scenario is throwing it in garbage before it is in production, eating the
          cost, and getting it done right. In the worst case, you are spending many
          years replacing the original application, at an incredible cost (based on our
          experience in the tech startup world, we would say most programmers are
          spending most of their time on this, and it's one of the reasons we started
          Lithic).
        </p>
        <h2>Next steps</h2>
        <p>
          If you haven't reached out yet, please <a href="#contact">get in touch</a> so
          we can learn more about you and your project, and see if we're a good fit.
        </p>
      </div>
    </Layout>
  );
}
